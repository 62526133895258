.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-paper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    outline: none;
    max-width: 850px;
    width: 90%;
    border-radius: 8px;
}

.modal-header {
    margin-bottom: 16px;
}

.modal-footer {
    margin-top: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  padding: 20px; /* Ajustar según tus necesidades */
  max-width: 400px;
  width: 100%;
  text-align: center;
  border-radius: 8px; /* Ajustar según el valor de theme.shape.borderRadius */
  box-shadow: 0px 3px 6px #00000029; /* Ajustar según el valor de theme.shadows[5] */
  background-color: #fff; /* Ajustar según el valor de theme.palette.background.paper */
}

.title {
  margin-bottom: 10px; /* Ajustar según el valor de theme.spacing(2) */
}

.textField {
  margin-bottom: 16px; /* Ajustar según el valor de theme.spacing(2) */
}

.button {
  margin-top: 16px; /* Ajustar según el valor de theme.spacing(2) */
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px; /* Ajustar según el valor de theme.spacing(2) */
}


.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Altura mínima igual al 100% de la altura de la ventana gráfica */
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.main {
  flex: 1; /* Ocupa todo el espacio disponible */
  padding: 20px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

/* Estilos para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .header, .footer {
      padding: 10px;
  }
}
/* Tabla */
.MuiTable-root {
  min-width: 650px!important;
}

/* Encabezado de la tabla */
.MuiTableHead-root {
  background-color: #031560!important;
}

.MuiTableCell-head {
  color: #fff!important;
  background-color: #031560!important;
  font-weight: bold!important;
  text-align: center!important;
}

/* Cuerpo de la tabla */
.MuiTableRow-root {
  &:hover {
      background-color: #e0f7fa!important;
  }
}

.MuiTableCell-body {
  text-align: center!important;
  padding: 7px!important;
}

/* Paginación de la tabla */
.MuiTablePagination-root {
  background-color: #fafafa!important;
  border-top: 1px solid #e0e0e0!important;
}

/* Botones */
.MuiButton-outlined {
  color: #031560!important;
  border: 1px solid #031560!important;
  text-transform: none!important;
  &:hover {
      background-color: #031560!important;
      color: #fff!important;
  }
}

/* Filtros */
.MuiFormControl-root {
  margin: 2px!important;
}

.MuiInputLabel-outlined {
  background-color: #fff!important;
}
.MuiGrid-item {
  padding-top: 0px!important;
  padding-left: 10px!important;
}

/* view.css */
nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  background-color: #031560;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}
