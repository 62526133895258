body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Altura mínima igual al 100% de la altura de la ventana gráfica */
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.main {
  flex: 1; /* Ocupa todo el espacio disponible */
  padding: 20px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

/* Estilos para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .header, .footer {
      padding: 10px;
  }
}